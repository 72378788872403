<template>
  <div>
    <div>
      <h4>Hello Schatz :)</h4>
    </div>
    <div>
      <Decrypt/>
    </div>
  </div>
</template>

<script>
import Decrypt from './components/Decrypt.vue'

export default {
  name: 'App',
  components: {
    Decrypt
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

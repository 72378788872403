<template>
  <div style="display: flex; justify-content: center; word-break: break-word">
    <!--    <div>-->
    <!--      priv key-->
    <!--      <div>-->
    <!--        <textarea cols="50" rows="30" v-bind:value="exportedPrivateKey">-->
    <!--        </textarea>-->
    <!--        <button @click="this.downloadJson('data', encryptedMessages)">download encrypted data</button>-->
    <!--      </div>-->
    <!--    </div>-->
    <div v-show="!enteredPrivateKey">
      Gib hier deinen Key ein
      <div>
        <textarea id="enteredKey" cols="50" rows="30" v-bind:value="enteredPrivateKey" placeholder="enter private key">
        </textarea>
      </div>
      <button @click="decrypt()">decrypt</button>
    </div>
    <div v-show="enteredPrivateKey">
      <div v-show="!restoredMessages">
        Entschlüssele unseren Chatverlauf ...
      </div>
      <div v-show="restoredMessages">
        <ul>
          <li v-for="message in restoredMessages" :key="message" class="messageEntry">
            <Message :message="message"></Message>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>

import Message from './Message.vue'

export default {

  name: 'DecryptComponent',
  components: {
    Message
  },
  async mounted() {
    const response = await fetch('/data');
    this.encryptedMessages = await response.json();

    console.log('fetched messages');

    // this.keyPair = await window.crypto.subtle.generateKey(
    //     {
    //       name: "RSA-OAEP",
    //       modulusLength: 4096,
    //       publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
    //       hash: {name: "SHA-256"},
    //     },
    //     true, // Whether the key is extractable
    //     ["encrypt", "decrypt"] // Usages of the key
    // );
    // console.log('generated key pair, encrypting messages...');
    //
    // const str = JSON.stringify(this.messages);
    // let chunks = this.chunkString(128)(str);
    //
    // chunks.forEach(async (message) => {
    //   let crypted = await this.encryptMessage(this.keyPair.publicKey, message);
    //   this.encryptedMessages.push(btoa(String.fromCharCode(...new Uint8Array(crypted))));
    // });
    //
    // this.exportedPrivateKey = await this.exportPrivateKey();

    console.log('encrypted messages : ', this.encryptedMessages);
  },
  computed: {
    getMessages(){
      return  [];
    }
  },
  methods: {
    chunkString(nSize) {
      return (strToChunk) => {
        let result = [];
        let chars = String(strToChunk).split('');

        for (let i = 0; i < (String(strToChunk).length / nSize); i++) {
          result = result.concat(chars.slice(i * nSize, (i + 1) * nSize).join(''));
        }
        return result;
      }
    },
    async exportPrivateKey() {
      let key = await window.crypto.subtle.exportKey("pkcs8", this.keyPair.privateKey);
      return btoa(String.fromCharCode(...new Uint8Array(key)));
    },
    async encryptMessage(key, message) {
      let enc = new TextEncoder();
      let encoded = enc.encode(message);
      let arrayBuffer = await window.crypto.subtle.encrypt(
          {
            name: "RSA-OAEP"
          },
          key,
          encoded
      );
      return new Uint8Array(arrayBuffer);
    },
    async decrypt() {
      console.log('decrypting...')
      const enteredKey = document.getElementById('enteredKey').value;
      this.enteredPrivateKey = enteredKey;
      const key = await this.importPkcs8Key(enteredKey);
      console.log('decryption key ', key);
      this.encryptedMessages.forEach(async (message) => {

        const decodedMessage = atob(message);
        const binaryString = new Uint8Array(decodedMessage.length);
        for (let i = 0; i < decodedMessage.length; i++) {
          binaryString[i] = decodedMessage.charCodeAt(i);
        }

        let decrypted = await this.decryptMessage(key, binaryString);
        this.decryptedMessages.push(decrypted);
        if (this.decryptedMessages.length === this.encryptedMessages.length) {
          this.restoredMessages = JSON.parse(this.decryptedMessages.join('')).messages
          this.restoredMessages.forEach(m => {
            let date = new Date(0);
            date.setUTCSeconds(m.date_created);
            m.date = date;
          });
        }
      });
    },
    async importPkcs8Key(base64Key) {
      console.log('importing key... ', base64Key);
      // Decode the base64 encoded key
      const decodedKey = atob(base64Key); // Convert from base64 to binary string
      const binaryString = new Uint8Array(decodedKey.length);

      for (let i = 0; i < decodedKey.length; i++) {
        binaryString[i] = decodedKey.charCodeAt(i);
      }
      console.log('binary string ', binaryString);

      // Now, binaryString is a BufferSource that can be used with importKey
      const key = await window.crypto.subtle.importKey(
          "pkcs8",
          binaryString.buffer, // Pass the underlying ArrayBuffer of the Uint8Array
          {name: "RSA-OAEP", hash: "SHA-256"}, // Adjust algorithm parameters as needed
          true, // Whether the key is extractable
          ["decrypt"] // Specify key usages
      );

      return key;
    },
    async decryptMessage(key, ciphertext) {
      let decrypted = await window.crypto.subtle.decrypt(
          {
            name: "RSA-OAEP"
          },
          key,
          ciphertext
      );
      let dec = new TextDecoder();
      return dec.decode(decrypted);
    },
    async downloadJson(filename, dataObj) {
      try {
        const jsonStr = JSON.stringify(dataObj);
        const blob = new Blob([jsonStr], {type: "application/json"}); // Use application/json MIME type
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename + '.json'); // Set the filename for the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading JSON:', error);
      }
    }
  },
  data() {
    return {
      keypair: null,
      messages: null,
      encryptedMessages: [],
      decryptedMessages: [],
      restoredMessages: null,
      exportedPrivateKey: null,
      enteredPrivateKey: null,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.messageEntry {
  width: 90%;
  padding-bottom: 1em;
}
</style>

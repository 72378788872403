<template>
  <div>
    <div :style=" message.from_person_id === 'Pascal' ? 'display: flex; justify-content: end' : 'display: flex; justify-content: start' " >
      <div :style=" message.from_person_id === 'Pascal' ? 'text-align: right' : 'text-align: left' ">
        <span style="font-weight: bold">{{ message.from_person_id }}</span>
      </div>
    </div>
    <div :style=" message.from_person_id === 'Julia' ? 'text-align: left':'text-align: right' ">
      <div>
        <div v-if="message.mssg === 'Sprachnachricht'">
          <span style="font-size: 0.8em; color: gray">Sprachnachrichten kommen noch =)</span>
        </div>
        <div v-else>
          {{ message.mssg }}
        </div>
      </div>
      <div style="font-size: 0.8em; color: gray">
        {{ formattedDate }}
      </div>
    </div>
  </div>
</template>

<script>
import dateFmtOptions from "@/components/dateFmtOptions";

export default {
  name: 'MessageComponent',
  computed: {
    formattedDate(){
      return new Date(this.message.date).toLocaleString('de-DE', dateFmtOptions)
    }
  },
  props: {
    message: {}
  },
  data() {
    return {}
  }
}
</script>

<style>

</style>
